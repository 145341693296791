import React from "react";

import "../Logo/LogoComponent.css";

function LogoComponent() {
  return (
    <a href="/">
      <img
        src="/images/GreenBearLogo_HomePage.png"
        alt="Logo"
        className="logo-img"
      />
    </a>
  );
}

export default LogoComponent;
