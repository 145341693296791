import React, { createContext, useState, useEffect } from "react";
import { Cookies } from "react-cookie-consent";

const CookiesContext = createContext();

export const CookiesProvider = ({ children }) => {
  const [cookiesConsent, setCookiesConsent] = useState(false);

  useEffect(() => {
    const consent = Cookies.get("yourAppCookieConsent");
    setCookiesConsent(consent === "true");
  }, []);

  return (
    <CookiesContext.Provider value={{ cookiesConsent, setCookiesConsent }}>
      {children}
    </CookiesContext.Provider>
  );
};

export default CookiesContext;
