// SEO.js
import React from "react";
import { Helmet } from "react-helmet";

const SEO = () => {
  return (
    <Helmet>
      {/* Title */}
      <title>Green Bear Software</title>

      {/* Meta Tags */}
      <meta
        name="description"
        content="Green Bear Software provides services in Game Development with Unreal Engine and Full-Stack Web Development"
      />
      <meta
        name="keywords"
        content="Game Development, Web Development, Unreal Engine, Unreal Engine C++, Blueprints, MERN Stack, React.js, Node.js, MongoDB, Express.js, Web Apps, Responsive Design, Frontend Development, Backend Development, API Integration, Branding, Logo Design, Visual Identity, Print Design, Digital Art, UI/UX Design, Interactive Design, 3D Modeling, Shader Development, Game Physics, Game Mechanics, Game Testing, Debugging, Continuous Integration, Version Control, Git, CMS, SEO Optimization, Web Hosting, Cloud Services, E-commerce Solutions, Animation, Motion Graphics, Go, Golang, Server, WebServer, Microservices"
      />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.greenbearsoftware.com/" />
      <meta property="og:title" content="Green Bear Software" />
      <meta
        property="og:description"
        content="Green Bear Software offers services in Game Development, Web Development, and Graphic Design. Explore our portfolio and get in touch with us!"
      />
      <meta
        property="og:image"
        content="https://www.greenbearsoftware.com/images/GreenBearLogo_HomePage.png"
      />

      {/* Twitter Card */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta
        property="twitter:url"
        content="https://www.greenbearsoftware.com/"
      />
      <meta property="twitter:title" content="Green Bear Software" />
      <meta
        property="twitter:description"
        content="Green Bear Software offers services in Game Development, Web Development, and Graphic Design. Explore our portfolio and get in touch with us!"
      />
      <meta
        property="twitter:image"
        content="https://www.greenbearsoftware.com/images/GreenBearLogo_HomePage.png"
      />
    </Helmet>
  );
};

export default SEO;
