import React from "react";
import i18next from "i18next";
import LogoComponent from "../Logo/LogoComponent";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import "../Navbar/Navbar.css";
import { useTranslation } from "react-i18next";
import { useModal } from "../../contexts/ModalContext";
import GlobeIcon from "../Icons/GlobeIcon";
function Navigationbar() {
  const { t } = useTranslation("navbar");
  const { showModal } = useModal();
  const currentLanguageCode = i18next.language;

  const { languages, elements, portfolioDropdown, contact } = t("data");
  function createNavbarElement(element) {
    return (
      <Nav.Link key={element.name} className="nav-item" href={element.link}>
        {element.name}
      </Nav.Link>
    );
  }
  function createPortfolioDropdownElement(element) {
    return (
      <NavDropdown.Item
        key={element.name}
        className="nav-item"
        href={element.link}
      >
        {element.name}
      </NavDropdown.Item>
    );
  }
  const changeLanguage = (lng) => {
    i18next.changeLanguage(lng);
  };

  return (
    <section className="black-section">
      <div className="gbs-navbar">
        <Navbar
          collapseOnSelect
          expand="lg"
          className="color-nav"
          variant="dark"
        >
          <Container>
            <Navbar.Brand>
              <LogoComponent />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link onClick={showModal} className="nav-item">
                  {contact.name}
                </Nav.Link>

                {elements.map(createNavbarElement)}

                {/*Portfolio Dropdown */}
                <NavDropdown
                  title={portfolioDropdown.dropdownName}
                  className="portfolio-dropdown"
                >
                  {portfolioDropdown.portfolioDropdownElements.map(
                    createPortfolioDropdownElement
                  )}
                </NavDropdown>

                <NavDropdown
                  title={<GlobeIcon height={20} width={20} />}
                  id="collasible-nav-dropdown"
                >
                  {languages.map((language, index) => (
                    <NavDropdown.Item
                      key={index}
                      className="nav-item"
                      disabled={currentLanguageCode === language.country_code}
                      onClick={() => changeLanguage(language.code)}
                    >
                      <img
                        src={language.image}
                        alt={language.name}
                        style={{
                          marginRight: "10px",
                          opacity:
                            language.country_code === currentLanguageCode
                              ? 0.5
                              : 1,
                        }}
                      />
                      {language.name}
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </section>
  );
}
export default Navigationbar;
