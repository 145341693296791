import axios from "axios";

const bUseLocalhost = false;

const URL = bUseLocalhost
  ? "http://localhost:7777/contact/submit"
  : "https://api.greenbearsw.tech/contact/submit";

export const submitContactForm = async (formData) => {
  try {
    const response = await axios.post(URL, formData, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data; // axios embeds the returned data inside a `data` property
  } catch (error) {
    console.error("Error submitting contact form:", error);
    throw error;
  }
};
