import axios from "axios";
import Cookies from "js-cookie"; // Use js-cookie for easier cookie handling

const bUseLocalhost = false;

const URL = bUseLocalhost
  ? "http://localhost:7777"
  : "https://api.greenbearsw.tech";

export const logVisitorData = async (visitorData) => {
  try {
    const response = await axios.post(`${URL}/log`, visitorData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data; // Returns the session_id if consent is given
  } catch (error) {
    console.error("Error logging visitor data:", error);
    throw error;
  }
};

export const logEvent = async (eventData) => {
  try {
    const response = await axios.post(`${URL}/event`, eventData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error logging event:", error);
    throw error;
  }
};

export const getVisitorData = () => {
  const userAgent = navigator.userAgent;
  const language = navigator.language;
  const referrer = document.referrer;
  const pageVisited = window.location.pathname;

  return {
    userAgent,
    language,
    referrer,
    pageVisited,
  };
};

// Function to log page views
export const logPageView = (pageName) => {
  const consent = Cookies.get("GreenBearCookies");
  if (!consent || consent === "false") {
    return; // Exit early if no consent
  }

  const sessionId = Cookies.get("GreenBearSession");
  if (!sessionId) {
    console.error("No session ID found");
    return;
  }

  const event = {
    session_id: sessionId,
    event: {
      eventType: `page_view: ${pageName}`,
      timestamp: new Date().toISOString(),
    },
  };

  logEvent(event)
    .then(() => {
      console.log(`Page view for ${pageName} logged`);
    })
    .catch((error) => {
      console.error(`Error logging page view for ${pageName}:`, error);
    });
};

// Function to log button clicks
export const logButtonClick = (buttonName) => {
  const consent = Cookies.get("GreenBearCookies");
  if (!consent || consent === "false") {
    return; // Exit early if no consent
  }

  const sessionId = Cookies.get("GreenBearSession");
  if (!sessionId) {
    console.error("No session ID found");
    return;
  }

  const event = {
    session_id: sessionId,
    event: {
      eventType: `button_click: ${buttonName}`,
      timestamp: new Date().toISOString(),
    },
  };

  logEvent(event)
    .then(() => {
      console.log(`Button click for ${buttonName} logged`);
    })
    .catch((error) => {
      console.error(`Error logging button click for ${buttonName}:`, error);
    });
};
