import React from "react";
import "./LoadingGif.css"; // Importing the CSS file

const LoadingGif = () => {
  const loadingGif = "images/gifs/loading.gif"; // Adjust the path accordingly
  const logoPath = "images/GreenBearLogo_HomePage.png"; // Adjust this path to your logo's location
  return (
    <div className="loading-gif-container">
      <img className="logo" src={logoPath} alt="Logo" />
      <p className="loading-text">Loading...</p>
      <img className="loader" src={loadingGif} alt="Loading..." />
    </div>
  );
};

export default LoadingGif;
