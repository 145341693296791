import React, { Suspense, lazy } from "react";
import "./App.css";
import { Outlet, Routes, Route } from "react-router-dom";
import NavigationBar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingGif from "./components/Gifs/LoadingGif";
import SEO from "./components/SEO/SEO";
import { ModalProvider } from "./contexts/ModalContext";
import { CookiesProvider } from "./contexts/CookiesContext";
import CookiesConsent from "./components/cookies/CookiesConsent";
import ContactForm from "./components/Modals/ContactModal";

const Home = lazy(() => import("./pages/Home"));
const About = lazy(() => import("./pages/About"));
const GameDevelopment = lazy(() => import("./pages/Portfolio/GameDevelopment"));
const WebDevelopment = lazy(() => import("./pages/Portfolio/WebDevelopment"));
const Blog = lazy(() => import("./pages/Blog/Blog"));

function App() {
  return (
    <CookiesProvider>
      <ModalProvider>
        <Suspense fallback={<LoadingGif />}>
          <Routes>
            <Route path="/" element={<LayoutsWithNavbar />}>
              <Route path="/loading" element={<LoadingGif />} />
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/gamedev" element={<GameDevelopment />} />
              <Route path="/webdev" element={<WebDevelopment />} />
              <Route path="/blog" element={<Blog />} />
            </Route>
          </Routes>
        </Suspense>
        <Footer />
        <ContactForm />
        <ToastContainer />
        <CookiesConsent />
      </ModalProvider>
    </CookiesProvider>
  );
}

export default App;

function LayoutsWithNavbar(props) {
  return (
    <>
      <SEO />
      <NavigationBar navlang={props.navlang} />
      <Outlet />
    </>
  );
}
