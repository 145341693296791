import React, { useContext } from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";
import CookiesContext from "../../contexts/CookiesContext";
import { logVisitorData, getVisitorData } from "../../utils/loggingUtils";
import "./CookiesConsent.css";

const CookiesConsent = () => {
  const { setCookiesConsent } = useContext(CookiesContext);

  const handleAccept = async () => {
    setCookiesConsent(true);
    Cookies.set("GreenBearCookies", true, {
      expires: 150,
      sameSite: "None",
      secure: true,
    });

    // Log visitor data
    const visitorData = getVisitorData();
    visitorData.consent = true;

    try {
      const response = await logVisitorData(visitorData);
      const sessionId = response.session_id;

      // Store the session_id in a cookie
      Cookies.set("GreenBearSession", sessionId, {
        expires: 150,
        sameSite: "None",
        secure: true,
      });
    } catch (error) {
      console.error("Error logging visitor data:", error);
    }
  };

  const handleDecline = () => {
    setCookiesConsent(false);
    Cookies.set("GreenBearCookies", false, {
      expires: 150,
      sameSite: "None",
      secure: true,
    });

    // Log basic visitor data without consent
    const visitorData = getVisitorData();
    visitorData.consent = false;

    logVisitorData(visitorData)
      .then(() => {
        console.log("Basic visitor data logged without consent");
      })
      .catch((error) => {
        console.error("Error logging basic visitor data:", error);
      });
  };

  return (
    <CookieConsent
      disableStyles={true}
      location="bottom" // Use built-in positioning at the bottom of the screen
      buttonClasses="btn btn-success" // Bootstrap green button
      declineButtonClasses="btn btn-danger" // Bootstrap red button
      containerClasses="alert alert-dark cookie-consent-container" // Custom container class for additional styling
      contentClasses="text-capitalize"
      buttonWrapperClasses="cookie-consent-buttons" // Custom class for button container
      buttonText="Accept"
      declineButtonText="Decline"
      enableDeclineButton
      onAccept={handleAccept}
      onDecline={handleDecline}
      cookieName="GreenBearCookies"
    >
      This website uses cookies to enhance the user experience.
    </CookieConsent>
  );
};

export default CookiesConsent;
