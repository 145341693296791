import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import "./ContactModal.css";
import { submitContactForm } from "../../utils/api";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { toast } from "react-toastify";
import { useModal } from "../../contexts/ModalContext";

function ContactForm() {
  const { isModalOpen, hideModal } = useModal();
  const [isSubmitting, setIsSubmitting] = useState(false); // Track form submission state
  const [emailError, setEmailError] = useState(""); // Error state for email validation
  const [phoneNumber, setPhoneNumber] = useState();

  const isValidEmail = (email) => {
    // Simple regex for email validation
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const name = formData.get("name");
    const email = formData.get("email");
    const message = formData.get("message");

    if (!isValidEmail(email)) {
      setEmailError("Please enter a valid email address");
      return;
    } else {
      setEmailError(""); // Reset error if email is valid
    }

    setIsSubmitting(true); // Start submission, disable the button

    try {
      await submitContactForm({ name, email, message, phoneNumber });
      setIsSubmitting(false); // End submission
      toast.success("Form submitted successfully, we will get in touch.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      // Clear form fields and close the modal
      event.target.reset();
      hideModal();
    } catch (error) {
      setIsSubmitting(false); // End submission even on error
      toast.error("Failed to send your message");
      console.error("Error:", error.message);
      // You can optionally notify the user about the error here
    }
  };

  return (
    <>
      <Modal show={isModalOpen} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>Contact Us</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" name="name" required />
            </Form.Group>

            <Form.Group controlId="formEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" name="email" required />
              {emailError && <div className="text-danger">{emailError}</div>}
            </Form.Group>
            <Form.Group controlId="formPhoneNumber">
              <Form.Label>Phone Number (Optional)</Form.Label>
              <PhoneInput
                placeholder="Enter phone number"
                value={phoneNumber}
                onChange={setPhoneNumber}
                international
              />
            </Form.Group>
            <Form.Group controlId="formMessage">
              <Form.Label>Message</Form.Label>
              <Form.Control as="textarea" rows={3} name="message" required />
            </Form.Group>

            <Button variant="primary" type="submit" disabled={isSubmitting}>
              {isSubmitting ? "Submitting..." : "Submit"}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ContactForm;
