import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector) // detects user language
  .use(HttpApi) // loads translations using http
  .init({
    supportedLngs: ["en", "hu", "ro"],
    fallbackLng: "en",
    detection: {
      order: [
        "querystring",
        "cookie",
        "localStorage",
        "sessionStorage",
        "navigator",
        "htmlTag",
        "path",
        "subdomain",
      ],
      caches: ["cookie"], // cache user language on
    },
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json", // path where resources get loaded from
    },
    ns: ["about", "home", "navbar", "portfolio", "services"],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    returnObjects: true,
  });

export default i18n;
