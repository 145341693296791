import React from "react";
import "../Footer/Footer.css";
import { useModal } from "../../contexts/ModalContext"; // Adjust the path as necessary
import { logButtonClick } from "../../utils/loggingUtils"; // Import the logButtonClick function

function Footer() {
  const currentYear = new Date().getFullYear();
  const facebookLink =
    "https://www.facebook.com/Green-Bear-Software-104809641945479/";
  const youtubeLink =
    "https://www.youtube.com/channel/UC_mmKly-z8Lmcmdz_vS0beQ";
  const linkedinLink =
    "https://www.linkedin.com/company/green-bear-software-s-r-l";
  const { showModal } = useModal(); // Use the context to control the modal
  const copyRightNotice = `© Copyright Green Bear Software S.R.L ${currentYear}`;

  const handleSocialIconClick = (platform) => {
    logButtonClick(`${platform} link clicked`);
  };

  return (
    <footer className="white-section" id="footer">
      <div className="align-Center">
        <a
          href={facebookLink}
          onClick={() => handleSocialIconClick("Facebook")}
        >
          <i className="social-icon fab fa-facebook-f"></i>
        </a>
        <a href={youtubeLink} onClick={() => handleSocialIconClick("YouTube")}>
          <i className="social-icon fab fa-youtube"></i>
        </a>
        <a
          href={linkedinLink}
          onClick={() => handleSocialIconClick("LinkedIn")}
        >
          <i className="social-icon fab fa-linkedin-in"></i>
        </a>
        <button
          onClick={() => {
            logButtonClick("Contact modal opened");
            showModal();
          }}
          style={{ background: "none", border: "none" }}
        >
          <i
            className="social-icon fas fa-envelope"
            style={{ cursor: "pointer" }}
          ></i>
        </button>
        <p className="copyright-notice">{copyRightNotice}</p>
      </div>
    </footer>
  );
}

export default Footer;
